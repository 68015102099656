
import React from 'react';
import {
	match,
	withRouter
} from 'react-router-dom';
import * as H from 'history';
// import SwiperBlock from './swiper'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Autoplay, EffectFade } from "swiper";

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import bg_intro1 from '../../static/pics/bg/bg-lg-left-1.svg';
import bg_intro2 from '../../static/pics/bg/bg-lg-left-2.svg';
import slide_intro1 from '../../static/pics/intro/1.jpg';
import slide_intro2 from '../../static/pics/intro/2.jpg';
import slide_intro3 from '../../static/pics/intro/3.jpg';

type MainPageProps = {
	store                 : any,
	match                 : match;
	location              : H.Location,
	history               : H.History,
}
type MainPageState = {
}

class MainPage extends React.Component<MainPageProps, MainPageState> {

	store                 : any;

	render() {

		return (
			<main className="s-main">
				<section className="sec-intro" id="intro">
					<img className="sec-bg bg-left d-none d-xl-block" src={ bg_intro1 } alt="" />
					<p className="btn-top"><a className="btn btn-lg" href="https://appv1.envelop.is/" target="_blank">Create SoulBound Token</a></p>
					<div className="container">
						<div className="intro__img">
							<Swiper
							  autoplay={{
							  	"delay": 2500,
							  	"disableOnInteraction": false
							  }}
							  spaceBetween={0}
							  slidesPerView={1}
							  modules={[Autoplay,EffectFade]}
							  effect="fade"
							>
								<SwiperSlide><img src={ slide_intro1 } className="swiper-slide" /></SwiperSlide>
								<SwiperSlide><img src={ slide_intro2 } className="swiper-slide" /></SwiperSlide>
								<SwiperSlide><img src={ slide_intro3 } className="swiper-slide" /></SwiperSlide>
							</Swiper>
						</div>
						<div className="intro__text">
							<h1>wNFT tickets</h1>
							
								<p>wNFT ticket is a unique service for accessing, ticketing, passcodes, that can be used for events in any format: from a simple meetup to a global conference.</p>
								<p><span className="font--bold">In order to use the service you need:</span> </p>
										<ul>
							  				<li><span className="font--bold">to mint</span> (create) NFT by adding the image and the  metadata;</li>
											<li><span className="font--bold">to create</span> an event in ENVELOP;</li>
											<li><span className="font--bold">to place</span> tickets up for sale using ENVELOP launchpad or any NFT marketplace;</li>
											<li><span className="font--bold">to redeem</span> the tickets with ENVELOP bot during your event.</li>
										</ul>
								
							
						</div>
					</div>
				</section>
				<div className="divider left"></div>
				<section className="sec-special" id="special"><img className="sec-bg bg-left d-none d-xl-block" src={ bg_intro2 } alt="" />
				  <div className="container">
				  	<div className="special__text">
				  		<h2>What Does Envelop wNFT Ticket Service Do</h2>
						<p><span className="font--bold">First</span>, you can not only create NFTs, but also assess them for validity according to a variety of criterias.</p>
						<p><span className="font--bold">Second</span>, wNFT from DAO ENVELOP can contain gifts inside tickets:</p>
							<ul className="list">
								<li>NFT (ERC-1155 & 721 standards in any of the EVM networks such as Ethereum, Polygon, BSC (BNB-chain), Harmony, Avalanche, Avrora (Near) and others); </li>
								<li>fungible tokens;</li>
								<li>native coins. </li>
							</ul>
						<p><span className="font--bold">Third</span>, DAO ENVELOP compiled the experience of hundreds of DAO friendly events and other associations and so you get a convenient product not only for event organizers but also for speakers and users.</p>	  	
						<p>On our application page <a href="https://appv1.envelop.is/" target="_blank">appv1.envelop.is</a> you can <strong>create an SBT as non-transferable wrapped NFT in 721 or 1155 standards</strong>.</p>
						<p>In addition, ENVELOP can create an ecosystem around your trade show, conference, webinar and other events: from token issuance to IDO (SAFT), to innovative wNFT farming with the interest you are willing to share with users.</p>
						<p>&nbsp;</p>
						
						<p>&nbsp;</p>
						<p><strong>ATTENTION! Disclaimer: You must understand that this tool is provided "as is", that is, all possible errors and shortcomings are your responsibility. In addition, this tool is created using blockchain technology, which we have no influence on.</strong></p>
						<p>&nbsp;</p>
						<p>If you want to get a more customized interaction with the tool, then please contact us by email: <a href="mailto:info@envelop.is" target="_blank">info@envelop.is</a>, as well as through the public telegram channel: <a href="https://t.me/envelop_en" target="_blank">t.me/envelop_en</a>. If you want to learn more about DAO ENVELOP projects, then refer to the documentation: <a href="https://docs.envelop.is" target="_blank">docs.envelop.is</a></p>
				    </div>
				  </div>
				</section>
			</main>
		)
	}
}

export default withRouter(MainPage);