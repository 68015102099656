import React                   from 'react';
import {
	HashRouter as Router,
	Route,
} from "react-router-dom";

import Header     from '../Header';
import Footer     from '../Footer';
import MainPage   from '../MainPage';

type AppParamsType = {}

class App extends React.Component<AppParamsType> {
	store          : any;

	render() {

		return (
			<Router>
				<Route path="/">
					<Header
						store = { this.store }
					/>
				</Route>

				<Route path="/">
					<MainPage
						store = { this.store }
					/>
				</Route>

				<Footer />
			</Router>
		)
	}
}

export default App;